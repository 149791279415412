import { useTranslation } from 'react-i18next';

import { SerializableAnnotation } from '../../../types';
import { Button } from '../../shadcn-ui/button';
import {
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../shadcn-ui/dialog';
import { ScrollArea } from '../../shadcn-ui/scroll-area';

type AnnotationViewerProps = {
  annotation: Pick<SerializableAnnotation, 'title' | 'description'> &
    Partial<Pick<SerializableAnnotation, 'position' | 'cameraPosition'>>;
};

const AnnotationViewer: React.FC<AnnotationViewerProps> = ({ annotation }) => {
  const { t } = useTranslation();

  return (
    <DialogContent className="light" aria-describedby="">
      <DialogHeader>
        <DialogTitle className="text-foreground">
          {annotation.title}
        </DialogTitle>
      </DialogHeader>

      <ScrollArea>
        <div
          className="orto-quill-viewer text-foreground [&_a]:text-[revert]"
          dangerouslySetInnerHTML={{ __html: annotation.description }}
        ></div>
      </ScrollArea>

      <DialogFooter className="flex gap-4">
        <DialogClose asChild>
          <Button
            type="submit"
            className="bg-primary capitalize text-primary-foreground"
          >
            {t('close')}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};

export default AnnotationViewer;
