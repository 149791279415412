import { TooltipProvider } from '@radix-ui/react-tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../shadcn-ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../shadcn-ui/tooltip';
import { useTheme } from '../../shared/ThemeProvider';

type ToolButtonProps = {
  tooltip: string;
  iconSrc?: string;
} & React.HTMLProps<HTMLButtonElement>;

const ToolButton = React.forwardRef<HTMLButtonElement, ToolButtonProps>(
  ({ iconSrc, tooltip, children, onClick, disabled }, ref) => {
    const { theme } = useTheme();
    const { t } = useTranslation();

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              className="max-w-[32px] bg-foreground p-2 text-background shadow-none hover:scale-125 hover:bg-inherit disabled:opacity-25"
              onClick={onClick}
              disabled={disabled}
            >
              {iconSrc && (
                <img
                  src={`/assets/icons/${theme}__${iconSrc}`}
                  alt={`${tooltip} icon`}
                />
              )}
              {!iconSrc && children}
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t(tooltip)}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
);

export default ToolButton;
