import { Box, Camera, GalleryHorizontalEnd, Move, Trash2 } from 'lucide-react';
import { useCallback } from 'react';
import { CgMenuGridR } from 'react-icons/cg';
import { GrClose } from 'react-icons/gr';

import { useNavigationStore } from '../../../context/zustand/navigationStore';
import { usePotree } from '../../../hooks/usePotree';
import { useToggle } from '../../../hooks/useToggle';
import { potreeTools } from '../../../utils/potreeTools';
import { useSidebar } from '../../shadcn-ui/sidebar';
import AnnotationToolButtonAdd from '../annotations/AnnotationToolButtonAdd';
import AnnotationToolButtonVisibility from '../annotations/AnnotationToolButtonVisibility';
import Wrapper from '../shared/Wrapper';
import ToolButton from './ToolButton';
import ToolsSection from './ToolsSection';

const Tools = () => {
  const { isRightSideMenuOpen, potreeDisplayMode } = useToggle();
  const { potreeViewer } = usePotree();
  const { toggleSidebar, setOpen: setSidebarOpen } = useSidebar();

  const navigationState = useNavigationStore();

  const _togglePanoramaTour = useCallback(() => {
    const { keyboardEvents, enableKeyboardEvents, disableKeyboardEvents } =
      navigationState;
    if (keyboardEvents === 'enabled') {
      disableKeyboardEvents();
      // NOTE: close the slides sidebar as a workaround to suppress navigating through slides
      setSidebarOpen(false);
    } else if (keyboardEvents === 'disabled') {
      enableKeyboardEvents();
      setSidebarOpen(true);
    } else {
      throw Error(`Unsupported keyboardEnvents value: ${keyboardEvents}`);
    }
    potreeTools.togglePanoramaTour(potreeViewer);
  }, [navigationState, potreeViewer, setSidebarOpen]);

  const _withPotreeViewer = useCallback(
    (fn: (viewer: any) => void) => {
      return () => fn(potreeViewer);
    },
    [potreeViewer]
  );

  return (
    <Wrapper classNames={`relative flex items-center text-primary`}>
      <MenuToggle />

      <Wrapper
        classNames={`absolute left-6 bottom-6 w-[220px] h-[220px] rounded-full items-center justify-center flex transition-all transform
          ${
            isRightSideMenuOpen
              ? 'translate-x-0'
              : 'scale-0 -translate-x-[50%] translate-y-[50%] h-0 w-0'
          }
          `}
      >
        {/* Measurement section */}
        <ToolsSection menuLabel="measure" classNames={`fixed top-0`}>
          <ToolButton
            tooltip="measure_vertical"
            onClick={_withPotreeViewer(potreeTools.addHeightMeasurement)}
            iconSrc="vertical.svg"
            disabled={potreeDisplayMode === 'ground_view'}
          />
          <ToolButton
            tooltip="measure_horizontal"
            onClick={_withPotreeViewer(potreeTools.addHorizontalMeasurement)}
            iconSrc="horizontal.svg"
            disabled={potreeDisplayMode === 'cutting'}
          />
          <ToolButton
            tooltip="measure_distance"
            onClick={_withPotreeViewer(potreeTools.addDistanceMeasurement)}
            iconSrc="incline.svg"
            disabled={['ground_view', 'cutting'].includes(potreeDisplayMode)}
          />
          <ToolButton
            tooltip="measure_point"
            onClick={_withPotreeViewer(potreeTools.addPointMeasurement)}
            iconSrc="dot.svg"
            disabled={potreeDisplayMode === 'ground_view'}
          />
          <ToolButton
            tooltip="measure_remove"
            onClick={_withPotreeViewer(potreeTools.removeMeasurements)}
          >
            <Trash2 size={16} />
          </ToolButton>
        </ToolsSection>

        {/* Slides section */}
        <ToolsSection
          menuLabel="slides"
          classNames={`fixed left-0 top-1/2 -translate-x-1/2 -translate-y-1/2`}
        >
          <ToolButton tooltip="slides_toggle" onClick={toggleSidebar}>
            <GalleryHorizontalEnd size={16} />
          </ToolButton>
        </ToolsSection>

        {/* Photodata section */}
        <ToolsSection menuLabel="photos" classNames={`fixed`}>
          <ToolButton
            tooltip="photos_toggle"
            onClick={_togglePanoramaTour}
            disabled={
              potreeDisplayMode !== 'model' ||
              potreeViewer.scene.panoramaTours.length === 0
            }
          >
            <Camera size={16} />
          </ToolButton>
        </ToolsSection>

        {/* Annotations section */}
        <ToolsSection
          menuLabel="annotations"
          classNames={`fixed right-0 top-1/2 translate-x-1/2 -translate-y-1/2`}
        >
          <AnnotationToolButtonAdd disabled={potreeDisplayMode !== 'model'} />
          <AnnotationToolButtonVisibility
            disabled={potreeDisplayMode !== 'model'}
          />
        </ToolsSection>

        {/* Navigation section */}
        <ToolsSection
          menuLabel="navigate"
          classNames={`fixed bottom-0 right-0`}
        >
          <ToolButton tooltip="navigate_move" disabled>
            <Move size={16} />
          </ToolButton>
          <ToolButton tooltip="navigate_box" disabled>
            <Box size={16} />
          </ToolButton>
        </ToolsSection>
      </Wrapper>
    </Wrapper>
  );
};

const MenuToggle = () => {
  const { isRightSideMenuOpen, setIsRightSideMenuOpen } = useToggle();

  return (
    <button
      onClick={() => setIsRightSideMenuOpen(() => !isRightSideMenuOpen)}
      className={`group fixed z-10 flex transform items-center gap-8 rounded-lg bg-[#F5F5F5] p-4 text-primary transition`}
    >
      {isRightSideMenuOpen ? (
        <GrClose
          size={24}
          className={`scale-75 transition-all group-hover:scale-100`}
        />
      ) : (
        <CgMenuGridR
          size={24}
          className={`transition-all group-hover:scale-125`}
        />
      )}
    </button>
  );
};

export default Tools;
