import { Plus } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useNavigationStore } from '../../../context/zustand/navigationStore';
import { useSlidesStore } from '../../../context/zustand/slidesStore';
import { useAuth } from '../../../hooks/useAuth';
import { usePotree } from '../../../hooks/usePotree';
import { AnnotationPotreeObject, SerializableAnnotation } from '../../../types';
import { OrtoSceneHelper } from '../../../utils/OrtoSceneHelper';
import { Dialog } from '../../shadcn-ui/dialog';
import ToolButton from '../shared/ToolButton';
import AnnotationEditor, { AnnotationEditorOnChange } from './AnnotationEditor';
import { updateScene } from './helpers/updateBackend';

type NewAnnotation = Pick<SerializableAnnotation, 'title' | 'description'> &
  Partial<Pick<SerializableAnnotation, 'position' | 'cameraPosition'>>;

const BLANK_ANNOTATION: NewAnnotation = {
  title: '',
  description: '',
};

type AnnotationToolButtonAddProps = {
  disabled?: boolean;
};

const AnnotationToolButtonAdd: React.FC<AnnotationToolButtonAddProps> = ({
  disabled,
}) => {
  // Hooks
  const { potreeViewer, project, setProject } = usePotree();
  const { token } = useAuth();

  const navigationState = useNavigationStore();
  const activeSlide = useSlidesStore((state) => state.activeSlide);

  const { t } = useTranslation();

  // State
  const [newAnnotation, setNewAnnotation] =
    useState<NewAnnotation>(BLANK_ANNOTATION);
  const [newAnnotationObject, setNewAnnotationObject] =
    useState<AnnotationPotreeObject | null>(null);

  // Local functions
  const _clearAnnotationState = () => {
    setNewAnnotationObject(null);
    setNewAnnotation(BLANK_ANNOTATION);
    navigationState.enableKeyboardEvents();
  };

  const _addAnnotation = () => {
    // NOTE: current workaround for handling PotreeAnnotationObjects and SerializableAnnotations simultaneously.
    if (!newAnnotationObject) return;
    newAnnotationObject.title = newAnnotation.title;
    newAnnotationObject.description = newAnnotation.description;

    const { id, field_scene_type: mode } = activeSlide;
    const scene = project?.field_scenes[mode]?.scenes.find((s) => s.id === id);

    if (!scene) {
      toast.error('Could not find which scene to append the annotation to.');
      throw Error(
        `Scene not found in project. [sceneID=${id}, displayMode='${mode}']`
      );
    }

    if (scene?.json_data[0].value.annotations) {
      scene.json_data[0].value.annotations.push(
        OrtoSceneHelper.createAnnotationsData(newAnnotationObject)
      );
    } else {
      scene.json_data[0].value.annotations = [
        OrtoSceneHelper.createAnnotationsData(newAnnotationObject),
      ];
    }

    // Helper method that performs a REST request to update scene with new annotation + update project
    updateScene(scene, {
      mode,
      setProject,
      token,
    });

    _clearAnnotationState();
  };

  const _editAnnotation: AnnotationEditorOnChange = useCallback(
    (key, value) => setNewAnnotation((prev) => ({ ...prev, [key]: value })),
    []
  );

  const _cancelAddingAnnotation = () => {
    potreeViewer.scene.removeAnnotation(newAnnotationObject);
    _clearAnnotationState();
  };

  /** Callback that is triggered by POTREE on insertion submit, see Potree AnnotationTool#startInsertion */
  const _potreeSubmitInsertion = (annotationObject: AnnotationPotreeObject) => {
    setNewAnnotationObject(annotationObject);
    setNewAnnotation((prev) => ({
      ...prev,
      position: annotationObject.position.toArray(),
      cameraPosition: annotationObject.cameraPosition?.toArray(),
    }));
  };

  /** Callback that is triggered by POTREE on insertion cancellation, see Potree AnnotationTool#startInsertion */
  const _potreeCancelInsertion = () => {
    // NOTE: currently there is nothing special to do here
    // Annotation cleanup is handled in potree
    navigationState.enableKeyboardEvents();
  };

  return (
    <Dialog open={!!newAnnotationObject}>
      <ToolButton
        tooltip="annotations_add"
        disabled={disabled}
        onClick={() => {
          navigationState.disableKeyboardEvents();

          potreeViewer.annotationTool.startInsertion(
            { title: t('annotations_new') },
            {
              finish: _potreeSubmitInsertion,
              cancel: _potreeCancelInsertion,
            }
          );
        }}
      >
        <Plus size={16} />
      </ToolButton>

      <AnnotationEditor
        annotation={newAnnotation}
        submitLabel="create"
        onChange={_editAnnotation}
        onSubmit={_addAnnotation}
        onCancel={_cancelAddingAnnotation}
      />
    </Dialog>
  );
};

export default AnnotationToolButtonAdd;
