import { Plus } from 'lucide-react';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useNavigationStore } from '../../../context/zustand/navigationStore';
import {
  ActiveSlide,
  NewSlide,
  useSlidesStore,
} from '../../../context/zustand/slidesStore';
import { useAuth } from '../../../hooks/useAuth';
import { usePorteeSlideManager, usePotree } from '../../../hooks/usePotree';
import { useToggle } from '../../../hooks/useToggle';
import { OrtoScene, PotreeDisplayModes } from '../../../types';
import { OrtoSceneHelper } from '../../../utils/OrtoSceneHelper';
import {
  authTokenHeader,
  cn,
  contentfulRichText,
  unescapeHTML,
} from '../../../utils/helper';
import { Button } from '../../shadcn-ui/button';
import { DialogTrigger } from '../../shadcn-ui/dialog';
import Label from '../../shared/Label';
import Authenticated from '../shared/Authenticated';
import Wrapper from '../shared/Wrapper';
import SlideEditor, { EditedSlide, SlideEditorOnChange } from './SlideEditor';
import SlideMenu from './SlideMenu';

interface SceneSlidesProps {
  mode: PotreeDisplayModes;
  modeScenes: OrtoScene[];
}

const _showDescription = (
  scene: OrtoScene,
  activeSlide: ActiveSlide
): boolean => {
  // Only show the description on the active (i.e. currently selected) slide
  const isActive = scene.id === activeSlide.id;
  // Only show the description if it is defined and not all whitespace
  // Don't show blank rich text descriptions (generated by Quill)
  const description = scene.field_description?.value;
  return isActive && contentfulRichText(description);
};

const SceneSlides: React.FC<SceneSlidesProps> = ({
  mode: sceneSlideKey,
  modeScenes: sceneSlideScenes,
}) => {
  const { token } = useAuth();
  const { project, setProject, potreeViewer } = usePotree();
  const potreeSlideManager = usePorteeSlideManager();
  const { potreeDisplayMode } = useToggle();

  const state = useSlidesStore();
  const { activeSlide, setActiveSlide, newSlide, setNewSlide, resetNewSlide } =
    state;

  const handleViewScene = useCallback(
    (scene: OrtoScene) => {
      if (!scene || scene.id === activeSlide.id) return;

      potreeSlideManager.goToSlide(scene, potreeDisplayMode);
    },
    [potreeSlideManager, potreeDisplayMode, activeSlide.id]
  );

  const keyboardEvents = useNavigationStore((state) => state.keyboardEvents);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const displayModeScenes =
        project?.field_scenes[potreeDisplayMode]?.scenes;

      if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && displayModeScenes) {
        e.preventDefault();

        const currentIndex = displayModeScenes.findIndex(
          (scene) => scene.id === activeSlide.id
        );

        let newIndex;
        if (e.key === 'ArrowUp') {
          newIndex =
            currentIndex === 0
              ? displayModeScenes.length - 1
              : currentIndex - 1;
        } else {
          newIndex =
            currentIndex === displayModeScenes.length - 1
              ? 0
              : currentIndex + 1;
        }

        const targetScene = displayModeScenes[newIndex];
        if (targetScene) {
          handleViewScene(targetScene);
        }
      }
    };

    if (keyboardEvents === 'enabled') {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    activeSlide.id,
    handleViewScene,
    potreeDisplayMode,
    project?.field_scenes,
    keyboardEvents,
  ]);

  const addSlide = async (slide: NewSlide) => {
    const body: any = JSON.stringify(
      OrtoSceneHelper.getScene(
        slide.field_scene_type,
        potreeViewer,
        slide.label,
        slide.field_description,
        slide.json_data[0]?.value?.view?.transitionDuration,
        {
          annotationOptions: {
            discard: !slide.copyAnnotations, // NOTE: if the user does not want to copy the existing annotations, discard them.
            resetIDs: true, // NOTE: Resets existing annotation IDs. This is a hack, necessary because the potreeViewer-content is basically carried over into the new slide (incluing IDs)...
          },
        }
      )
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${project?.id}/scene`,
        {
          method: 'PUT',
          headers: authTokenHeader(token),
          body,
        }
      );

      const newScene = await response.json();

      if (!newScene || !response.ok) {
        toast.error(`Adding scene '${newScene.label}' failed.`);
        return;
      }

      toast.success(`Scene '${newScene.label}' added.`);

      setProject((prevProject: any) => ({
        ...prevProject,
        field_scenes: {
          ...prevProject.field_scenes,
          [sceneSlideKey]: {
            ...prevProject.field_scenes[sceneSlideKey],
            scenes: [
              ...prevProject.field_scenes[sceneSlideKey].scenes,
              newScene,
            ],
          },
        },
      }));

      return newScene;
    } catch (error: any) {
      console.log('API request failed:', error.message);
      throw Error(error.message);
    }
  };

  const _handleSlideEditorSubmit = async (slide: NewSlide) => {
    if (hasErrors(slide)) return;

    // Add new slide (aka scene) to project
    const newSlide = await addSlide(slide);

    // Trigger navigation to the new slide
    handleViewScene(newSlide);
  };

  const updateSlide = async (slide: EditedSlide) => {
    const body: any = JSON.stringify(
      OrtoSceneHelper.getScene(
        slide.field_scene_type,
        potreeViewer,
        slide.label,
        slide.field_description,
        slide.json_data[0]?.value?.view?.transitionDuration
      )
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${slide.id}`,
        {
          method: 'POST',
          headers: authTokenHeader(token),
          body,
        }
      );

      const updatedScene = await response.json();

      if (!updatedScene || !response.ok) {
        toast.error(`Updating scene '${updatedScene}' failed.`);
        return;
      }

      toast.success(`Scene '${slide.label}' updated.`);

      setProject((prevProject: any) => ({
        ...prevProject,
        field_scenes: {
          ...prevProject.field_scenes,
          [sceneSlideKey]: {
            ...prevProject.field_scenes[sceneSlideKey],
            scenes: prevProject.field_scenes[sceneSlideKey].scenes.map(
              (scene: OrtoScene) =>
                scene.id === slide.id ? updatedScene : scene
            ),
          },
        },
      }));
    } catch (error: any) {
      console.log('API request failed:', error.message);
      throw Error(error.message);
    }
  };

  const deleteSlide = async (slideId: string) => {
    const slides = project?.field_scenes[potreeDisplayMode]?.scenes;
    if (!slides) {
      toast.error(`Could not find the ${potreeDisplayMode} slides`);
      return;
    }

    const deletedSlide = slides?.find((slide) => slide.id === slideId);
    if (!deletedSlide) {
      toast.error('Could not find the slide to delete');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${slideId}`,
        {
          method: 'DELETE',
          headers: authTokenHeader(token),
        }
      );

      const deletedScene = await response.json();

      if (!deletedScene || !response.ok) {
        toast.error(`Deleting scene '${deletedSlide.label}' failed.`);
        return;
      }

      toast.success(`Scene '${deletedSlide.label}' deleted.`);

      setProject((prevProject: any) => ({
        ...prevProject,
        field_scenes: {
          ...prevProject.field_scenes,
          [sceneSlideKey]: {
            ...prevProject.field_scenes[sceneSlideKey],
            scenes:
              prevProject.field_scenes[sceneSlideKey]?.scenes.filter(
                (scene: OrtoScene) => scene.id !== slideId
              ) || [],
          },
        },
      }));

      // If the deleted slide was currently active, go to the first slide
      if (activeSlide.id === slideId) {
        handleViewScene(slides[0]);
      } else {
        // NOTE: hack for updating the UI, otherwise the active slide is not highlighted
        setActiveSlide(activeSlide);
      }
    } catch (error: any) {
      console.log('API request failed:', error.message);
      throw Error(error.message);
    }
  };

  const handleUpdateSlide = (slide: EditedSlide) => {
    if (hasErrors(slide)) {
      return;
    }

    updateSlide(slide);
  };

  const hasErrors = (slide: EditedSlide): boolean => {
    if (!slide.label.trim()) {
      toast.error('Scene label is required.');
      return true;
    }
    return false;
  };

  const _handleSlideChange = useCallback<SlideEditorOnChange>(
    (key, value) => {
      if (key === 'transitionDuration') {
        const slideCopy = { ...newSlide };
        slideCopy.json_data[0].value.view.transitionDuration = value as number;
        setNewSlide(slideCopy);
      } else {
        setNewSlide({ ...newSlide, [key]: value });
      }
    },
    [newSlide, setNewSlide]
  );

  const scenes = (
    <Wrapper classNames="flex flex-col gap-3">
      {sceneSlideScenes.map((scene, index) => (
        <div key={`scene-${scene.id}`}>
          <div
            className={cn(
              'flex flex-col gap-4 rounded-lg py-3',
              activeSlide.id === scene.id
                ? 'bg-primary text-white'
                : 'border border-muted bg-white text-primary'
            )}
          >
            {/* Scene card label and menu */}
            <div
              className="flex cursor-pointer justify-between gap-4"
              onClick={() => handleViewScene(scene)}
            >
              <div className="flex items-center pl-4">
                <div className="flex items-baseline gap-2">
                  <span className="text-xs-regular">{index + 1}</span>
                  <Label
                    text={scene.label}
                    classNames="text-sm-regular text-bold"
                  />
                </div>
              </div>

              <Authenticated>
                <SlideMenu
                  key={`slide_menu_${scene.id}`}
                  slideNumber={index + 1}
                  initialSlideState={scene}
                  handleUpdate={handleUpdateSlide}
                  handleDelete={() => {
                    deleteSlide(scene.id);
                  }}
                />
              </Authenticated>
            </div>

            {/* Scene card description */}
            {_showDescription(scene, activeSlide) && (
              <div
                className="flex flex-col gap-4 px-4 font-sans leading-snug text-muted [&_a]:text-white [&_a]:underline [&_p]:text-sm-regular [&_strong]:text-white"
                dangerouslySetInnerHTML={{
                  __html: unescapeHTML(scene.field_description!.value),
                }}
              />
            )}
          </div>
        </div>
      ))}

      <Authenticated>
        <Wrapper classNames="relative mb-6">
          <SlideEditor
            slideNumber={sceneSlideScenes.length + 1}
            slide={newSlide}
            onSlideChange={_handleSlideChange}
            onCancel={resetNewSlide}
            onSubmit={() => _handleSlideEditorSubmit(newSlide)}
            submitLabel="create"
            showCopyAnnotationsCheckbox
          >
            <DialogTrigger asChild>
              <Button
                size="icon"
                type="button"
                variant="outline"
                className="light"
                onClick={() => {
                  setNewSlide({
                    label: '',
                    // @ts-ignore
                    json_data: OrtoSceneHelper.getScene(
                      potreeDisplayMode,
                      potreeViewer,
                      ''
                    ).json_data,
                    field_scene_type: potreeDisplayMode,
                  });
                }}
              >
                <Plus size={20} />
              </Button>
            </DialogTrigger>
          </SlideEditor>
        </Wrapper>
      </Authenticated>
    </Wrapper>
  );

  return potreeDisplayMode === sceneSlideKey ? scenes : null;
};

export default SceneSlides;
