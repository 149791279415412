import { AnnotationPotreeObject } from '@/types';
import { Eye, EyeOff } from 'lucide-react';

import { useAnnotationsStore } from '../../../context/zustand/annotationsStore';
import ToolButton from '../shared/ToolButton';

type AnnotationToolButtonVisibilityProps = {
  disabled?: boolean;
};

const AnnotationToolButtonVisibility: React.FC<
  AnnotationToolButtonVisibilityProps
> = ({ disabled }) => {
  // State store
  const annotations = useAnnotationsStore((state) => state.annotations);
  const areAllAnnotationsHidden = useAnnotationsStore(
    (state) => state.areAllAnnotationsHidden
  );
  const setAllAnnotationsHidden = useAnnotationsStore(
    (state) => state.setAllAnnotationsHidden
  );

  return (
    <ToolButton
      tooltip="annotations_toggle_visibility"
      onClick={() => {
        annotations.forEach(
          (annotation: AnnotationPotreeObject) =>
            (annotation.visible = areAllAnnotationsHidden)
        );
        setAllAnnotationsHidden(!areAllAnnotationsHidden);
      }}
      disabled={disabled || annotations.length === 0}
    >
      {areAllAnnotationsHidden ? <Eye size={16} /> : <EyeOff size={16} />}
    </ToolButton>
  );
};

export default AnnotationToolButtonVisibility;
