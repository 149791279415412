import { useContext } from 'react';

import { PotreeContext } from '../context/PotreeProvider';
import { useAnnotationsStore } from '../context/zustand/annotationsStore';
import { useSlidesStore } from '../context/zustand/slidesStore';
import { OrtoScene, PotreeDisplayModes } from '../types';
import { OrtoSceneHelper } from '../utils/OrtoSceneHelper';

export const usePotree = () => {
  const context = useContext(PotreeContext);

  if (!context) {
    throw new Error('usePotree must be used within an PotreeProvider');
  }

  return context;
};

export const usePorteeSlideManager = () => {
  const { potreeViewer, setProjectPcs } = usePotree();

  const setActiveSlide = useSlidesStore((state) => state.setActiveSlide);

  const setAnnotations = useAnnotationsStore((state) => state.setAnnotations);
  const setAllAnnotationsHidden = useAnnotationsStore(
    (state) => state.setAllAnnotationsHidden
  );

  const goToSlide = (
    slide: OrtoScene,
    potreeDisplayMode: PotreeDisplayModes
  ) => {
    if (!potreeViewer) return;

    // Clear annotations and measurements from previous scene
    OrtoSceneHelper.clearScene(potreeViewer);
    OrtoSceneHelper.setScene(potreeViewer, slide, setProjectPcs);

    setActiveSlide({
      id: slide.id,
      field_scene_type: potreeDisplayMode,
    });

    setAnnotations(potreeViewer.scene.annotations.children);
    setAllAnnotationsHidden(false);
  };

  return {
    goToSlide,
  };
};
